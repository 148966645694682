import modal from './modules/modal/reducer';
import confirm from './modules/confirm/reducer';

const reducer = {
  common: {},
  data: {},
  ui: {
    modal,
    confirm,
  },
};

export default reducer;
