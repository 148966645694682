import { PercentOptionsType } from './Percent.types';

export default class Percents {
  private percentNumberFormat: Intl.NumberFormat;

  constructor({ locale = 'en-US' }: PercentOptionsType = {}) {
    this.percentNumberFormat = new Intl.NumberFormat(locale, {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  public format(number: number): string {
    return this.percentNumberFormat.format(number);
  }
}
