import React from 'react';
import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';

import Loader from '../Loader';

import {
  InfiniteScrollPropsType,
  InfiniteScrollStateType,
  InfiniteScrollElementType,
} from './InfiniteScroll.types';
import styles from './InfiniteScroll.module.scss';
import { DEFAULT_SCROLL_ELEMENT } from './InfiniteScroll.constants';

export default class InfiniteScroll extends React.Component<
  InfiniteScrollPropsType,
  InfiniteScrollStateType
> {
  private onLoadMorePromiseReject?: () => void;
  private scrollElement: InfiniteScrollElementType = DEFAULT_SCROLL_ELEMENT;

  constructor(props: InfiniteScrollPropsType) {
    super(props);

    this.state = {
      isLoading: false,
      previousPosition: undefined,
    };
  }

  componentDidMount() {
    const { useParentContainer = false, getContainer } = this.props;

    if (useParentContainer) {
      this.scrollElement = this.getParentElement();
    } else if (getContainer) {
      this.scrollElement = this.getElementByGetContainer();
    }

    this.addScrollEventListener();
  }

  componentDidUpdate(prevProps: InfiniteScrollPropsType) {
    const isGetContainerUpdated =
      prevProps.getContainer !== this.props.getContainer;
    const isUseParentContainerUpdated =
      prevProps.useParentContainer !== this.props.useParentContainer;

    if (isGetContainerUpdated || isUseParentContainerUpdated) {
      this.removeScrollEventListener();

      if (isUseParentContainerUpdated) {
        if (this.props.useParentContainer) {
          this.scrollElement = this.getParentElement();
        } else if (this.props.getContainer) {
          this.scrollElement = this.getElementByGetContainer();
        } else {
          this.scrollElement = DEFAULT_SCROLL_ELEMENT;
        }
      } else if (isGetContainerUpdated) {
        this.scrollElement = this.getElementByGetContainer();
      } else {
        this.scrollElement = DEFAULT_SCROLL_ELEMENT;
      }

      this.addScrollEventListener();
    }
  }

  componentWillUnmount() {
    if (this.onLoadMorePromiseReject) {
      this.onLoadMorePromiseReject();
    }

    this.removeScrollEventListener();
  }

  getParentElement = () => {
    const thisElement = ReactDOM.findDOMNode(this);

    return thisElement?.parentElement || DEFAULT_SCROLL_ELEMENT;
  };

  getElementByGetContainer = () => {
    const { getContainer } = this.props;

    return getContainer
      ? getContainer()?.current || DEFAULT_SCROLL_ELEMENT
      : DEFAULT_SCROLL_ELEMENT;
  };

  addScrollEventListener = () => {
    this.scrollElement.addEventListener('scroll', this.handleOnScroll, true);
  };

  removeScrollEventListener = () => {
    this.scrollElement.removeEventListener('scroll', this.handleOnScroll, true);
  };

  handleOnScroll = debounce((e: Event) => {
    const { previousPosition, isLoading } = this.state;
    const {
      isReverse = false,
      threshold = 150,
      hasMore = true,
      onLoadMore,
    } = this.props;
    // @ts-ignore
    const { scrollTop, offsetHeight, scrollHeight } = e.target || {};

    const needLoad = isReverse
      ? scrollTop - threshold <= 0
      : scrollTop + offsetHeight + threshold >= scrollHeight;

    let isScrollInDirection = true;

    if (previousPosition !== undefined) {
      isScrollInDirection = isReverse
        ? scrollTop < previousPosition
        : scrollTop > previousPosition;
    }

    if (needLoad && !isLoading && hasMore && isScrollInDirection) {
      this.setState({ isLoading: true });

      new Promise<unknown>((resolve, reject) => {
        this.onLoadMorePromiseReject = reject;
        onLoadMore().then(resolve).catch(noop);
      })
        .then(() => {
          this.onLoadMorePromiseReject = undefined;
          this.setState({ isLoading: false });
        })
        .catch(noop);
    }

    this.setState({ previousPosition: scrollTop });
  }, this.props.debounceWait || 300);

  render() {
    const { isLoading } = this.state;
    const { loader = <Loader />, isReverse = false, children } = this.props;
    const loaderElement = <div className={styles.loader}>{loader}</div>;

    return (
      <>
        {isLoading && isReverse && loaderElement}
        {children}
        {isLoading && !isReverse && loaderElement}
      </>
    );
  }
}
