import React from 'react';
import classnames from 'classnames';

import styles from './Flex.module.scss';
import { FlexType } from './Flex.types';

const Flex = React.forwardRef<HTMLElement, FlexType>(
  (
    {
      as: Component = 'div',
      align = 'stretch',
      alignItems = 'stretch',
      direction = 'row',
      inline = false,
      justify = 'flex-start',
      wrap = 'nowrap',
      fluid = false,
      gap,
      className,
      style,
      children,
      onClick,
    },
    ref
  ) => {
    return (
      <Component
        className={classnames(className, {
          [styles.alignContentCenter]: align === 'center',
          [styles.alignContentFlexEnd]: align === 'flex-end',
          [styles.alignContentFlexStart]: align === 'flex-start',
          [styles.alignContentSpaceAround]: align === 'space-around',
          [styles.alignContentSpaceBetween]: align === 'space-between',
          [styles.alignContentStretch]: align === 'stretch',

          [styles.alignItemsCenter]: alignItems === 'center',
          [styles.alignItemsBaseline]: alignItems === 'baseline',
          [styles.alignItemsFlexEnd]: alignItems === 'flex-end',
          [styles.alignItemsFlexStart]: alignItems === 'flex-start',
          [styles.alignItemsStretch]: alignItems === 'stretch',

          [styles.displayFlex]: !inline,
          [styles.displayInlineFlex]: inline,

          [styles.flexDirectionRow]: direction === 'row',
          [styles.flexDirectionRowReverse]: direction === 'row-reverse',
          [styles.flexDirectionColumn]: direction === 'column',
          [styles.flexDirectionColumnReverse]: direction === 'column-reverse',

          [styles.flexWrapWrap]: wrap === 'wrap',
          [styles.flexWrapNowrap]: wrap === 'nowrap',
          [styles.flexWrapWrapReverse]: wrap === 'wrap-reverse',

          [styles.justifyContentCenter]: justify === 'center',
          [styles.justifyContentStretch]: justify === 'stretch',
          [styles.justifyContentFlexEnd]: justify === 'flex-end',
          [styles.justifyContentFlexStart]: justify === 'flex-start',
          [styles.justifyContentSpaceAround]: justify === 'space-around',
          [styles.justifyContentSpaceBetween]: justify === 'space-between',
          [styles.justifyContentSpaceEvenly]: justify === 'space-evenly',

          [styles.fluid]: fluid,
        })}
        style={{
          ...(gap
            ? {
                columnGap: gap[0],
                gridColumnGap: gap[0],
                gridRowGap: gap[1],
                rowGap: gap[1],
              }
            : {}),
          ...style,
        }}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);

export default Flex;
