import * as Yup from 'yup';

import { PASSWORD_REGEX } from '../..';
import { getI18nErrorMessage } from '../../i18n';

export const PASSWORD_REQUIRED_VALIDATION_SCHEMA = Yup.string().required(
  'Please enter your password'
);

export const PASSWORD_VALIDATION_SCHEMA = PASSWORD_REQUIRED_VALIDATION_SCHEMA.min(
  8,
  ({ min }) => getI18nErrorMessage('core.error.password.minLength', { min })
).matches(PASSWORD_REGEX, getI18nErrorMessage('core.error.password.incorrectFormat'));

export const PASSWORD_CONFIRM_VALIDATION_SCHEMA = createPasswordConfirmValidationSchema(
  'password'
);

export function createPasswordConfirmValidationSchema(fieldName: string) {
  return Yup.string()
    .required(getI18nErrorMessage('core.error.passwordConfirm.required'))
    .when(fieldName, {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref(fieldName)],
        getI18nErrorMessage('core.error.passwordConfirm.notMatch')
      ),
    });
}
