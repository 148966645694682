import * as Yup from 'yup';
import isEmail from 'validator/lib/isEmail';

import { getI18nErrorMessage } from '../../i18n';

export const EMAIL_VALIDATION_SCHEMA = Yup.string()
  .required(getI18nErrorMessage('core.error.email.required'))
  .test(
    'isEmail',
    getI18nErrorMessage('core.error.email.incorrectFormat'),
    (email) => (email ? isEmail(email) : true)
  )
  .max(100, ({ max }) =>
    getI18nErrorMessage('core.error.email.maxLength', { max })
  );
