import isEqual from 'lodash/isEqual';
import { Currency, CurrencyOptionsType } from '@appclose/lib';

let _currency: Currency;
let _options: CurrencyOptionsType;

export default function currency(options: CurrencyOptionsType = {}): Currency {
  if (!_currency || !isEqual(_options, options)) {
    _options = options;
    _currency = new Currency(options);
  }

  return _currency;
}
