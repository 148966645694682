import { Field } from 'formik';
import React from 'react';
import DatePickerFormFieldWrapper from '../DatePickerFormFieldWrapper';
import { DatePickerFormFieldType } from './DatePickerFormField.types';

const DatePickerFormField = ({
  allowInput = true,
  dateFormat = 'MM/DD/YYYY',
  placeholder,
  ...rest
}: DatePickerFormFieldType) => {
  return (
    <Field
      allowInput={allowInput}
      dateFormat={dateFormat}
      placeholder={placeholder || dateFormat}
      {...rest}
      mode="single"
      component={DatePickerFormFieldWrapper}
    />
  );
};

export default DatePickerFormField;
