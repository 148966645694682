import React, {
  useState,
  SyntheticEvent,
  useRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import noop from 'lodash/noop';
import classnames from 'classnames';
import { Manager, Reference, Popper } from 'react-popper';
import OutsideClickHandler from 'react-outside-click-handler';

import styles from './Dropdown.module.scss';
import {
  DropdownPropsType,
  DropdownRefType,
  DropdownToggleSource,
} from './Dropdown.types';

const Dropdown = React.forwardRef<DropdownRefType, DropdownPropsType>(
  (
    {
      isOpen = false,
      className,
      container = document.body,
      content,
      disabled = false,
      dropdownConfig = noop,
      onDropdownToggle,
      onToggleClick,
      onContentClickHandler = () => true,
      position = 'bottom',
      toggleComponent,
      theme,
    },
    ref
  ) => {
    const [isPopperOpen, setIsPopperOpenInner] = useState<boolean>(isOpen);
    const [isFocused, setIsFocused] = useState(false);

    const onFocus = useCallback(() => setIsFocused(true), []);
    const onBlur = useCallback(() => setIsFocused(false), []);

    const setIsPopperOpen = useCallback(
      (value: boolean, source: DropdownToggleSource) => {
        setIsPopperOpenInner(value);

        if (value !== isPopperOpen && onDropdownToggle) {
          onDropdownToggle(value, source);
        }
      },
      [setIsPopperOpenInner, onDropdownToggle, isPopperOpen]
    );

    const toggleRef = useRef<HTMLDivElement>();

    useImperativeHandle(ref, () => ({
      isOpen: isPopperOpen,
      setIsOpen: (isOpen) => setIsPopperOpen(isOpen, 'ref'),
    }));

    useEffect(() => {
      if (!isPopperOpen) {
        setIsFocused(false);
      }
    }, [isPopperOpen]);

    const onOutsideClick = (e: SyntheticEvent<HTMLElement, MouseEvent>) => {
      if (isPopperOpen) {
        // @ts-ignore
        const isClickOnTarget = toggleRef?.current?.contains(e.target);

        if (!isClickOnTarget) {
          setIsPopperOpen(false, 'outside');
        }
      }
    };

    const onContentClick = (e: SyntheticEvent<HTMLElement, MouseEvent>) => {
      if (onContentClickHandler(e)) {
        setIsPopperOpen(false, 'content');
      }

      e.stopPropagation();
    };

    const handleOnToggleMouseUp = useCallback(
      (e) => {
        if (!disabled && isFocused) {
          setIsPopperOpen(!isPopperOpen, 'target');

          if (onToggleClick) {
            onToggleClick();
          }
        }

        e.stopPropagation();
      },
      [disabled, isPopperOpen, onToggleClick, setIsPopperOpen, isFocused]
    );

    const handleOnToggleClick = useCallback(
      (e: SyntheticEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
      },
      []
    );

    useEffect(() => {
      setIsPopperOpenInner(isOpen);
    }, [isOpen]);

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              className={classnames(styles.toggle, className)}
              onMouseUp={handleOnToggleMouseUp}
              onMouseDown={onFocus}
              onClick={handleOnToggleClick}
              onFocus={onFocus}
              onTouchStart={onFocus}
              onBlur={onBlur}
              ref={(r) => {
                // @ts-ignore
                ref(r);
                // @ts-ignore
                toggleRef.current = r;
              }}
            >
              {toggleComponent &&
                toggleComponent({ isDropdownOpen: isPopperOpen })}
            </div>
          )}
        </Reference>
        {isPopperOpen &&
          ReactDOM.createPortal(
            <OutsideClickHandler onOutsideClick={onOutsideClick}>
              <Popper
                modifiers={[
                  {
                    name: 'preventOverflow',
                    options: { rootBoundary: 'viewport' },
                  },
                  {
                    name: 'dropDownConfig',
                    enabled: !!dropdownConfig,
                    phase: 'write',
                    fn: dropdownConfig,
                  },
                ]}
                placement={position}
              >
                {({ ref, style, placement }) => {
                  const contentIsNode = typeof content !== 'function';
                  const Content = content;

                  return (
                    <div
                      onClick={onContentClick}
                      data-placement={placement}
                      style={style}
                      ref={ref}
                      className={classnames(styles.content, theme?.content)}
                    >
                      {contentIsNode ? (
                        content
                      ) : (
                        // @ts-ignore
                        <Content />
                      )}
                    </div>
                  );
                }}
              </Popper>
            </OutsideClickHandler>,
            container
          )}
      </Manager>
    );
  }
);

export default Dropdown;
