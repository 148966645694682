import React, { useCallback, useState } from 'react';

import { AccordionContext } from './Accordion.context';
import { AccordionPropsType } from './Accordion.types';

export default function Accordion({ children }: AccordionPropsType) {
  const [expanded, setExpanded] = useState(false);

  const onChange = useCallback(() => setExpanded((prev) => !prev), []);

  return (
    <AccordionContext.Provider value={{ expanded, onChange }}>
      <section>{children}</section>
    </AccordionContext.Provider>
  );
}
