import dateManager from '../../../controllers/dateManager';
import { DatePickerValueType } from './DatePicker.types';

export const NUMBER_TIME_FORMATS = ['x', 'X'];
export const INPUT_FORMAT = 'MM/DD/YYYY';
export const INPUT_DATE_MASK = /^((?:\d{0,2})?)(\/?)((?:\d{0,2})?)(\/?)((?:\d{0,4})?)$/;

function prepareDatePickerDate(
  value: any,
  dateFormat?: string
): number | undefined {
  return value
    ? dateManager().parse(value, dateFormat, true).valueOf()
    : undefined;
}

export const prepareDatePickerValues = (
  value: any,
  mode: 'range' | 'single',
  dateFormat?: string
) => {
  let datePickerValue: number | undefined | (number | undefined)[];

  if (mode === 'range') {
    const rangedValue = value as { from?: string; to?: string } | undefined;

    datePickerValue = [
      prepareDatePickerDate(rangedValue?.from, dateFormat),
      prepareDatePickerDate(rangedValue?.to, dateFormat),
    ];
  } else {
    datePickerValue = prepareDatePickerDate(value, dateFormat);
  }

  return datePickerValue;
};

function prepareOutputDate(
  date: Date,
  outputDateFormat?: string,
  round?: 'startDay' | 'endDay'
) {
  let parsedDate = dateManager().parse(date);

  if (round === 'startDay') {
    parsedDate = parsedDate.startOf('day');
  } else if (round === 'endDay') {
    parsedDate = parsedDate.endOf('day');
  }

  if (outputDateFormat && NUMBER_TIME_FORMATS.includes(outputDateFormat)) {
    return parseInt(parsedDate.format(outputDateFormat), 10);
  }

  return parsedDate.format(outputDateFormat);
}

export const prepareOutputValues = (
  dates: Date[],
  mode: 'range' | 'single',
  outputDateFormat?: string,
  endOfDate?: boolean
): DatePickerValueType => {
  switch (mode) {
    case 'range':
      const from = dates[0]
        ? prepareOutputDate(dates[0], outputDateFormat, 'startDay')
        : null;
      const to = dates[1]
        ? prepareOutputDate(dates[1], outputDateFormat, 'endDay')
        : null;

      return { from, to };

    case 'single':
      return dates[0]
        ? prepareOutputDate(
            dates[0],
            outputDateFormat,
            endOfDate ? 'endDay' : 'startDay'
          )
        : null;
  }

  return dates.map((date) => prepareOutputDate(date, outputDateFormat));
};
