import { useEffect, useState } from 'react';
import { IntlShape } from 'react-intl';

import { configureIntl, setIntlInstance } from '../../controllers/intl';
import { UseConfigureIntlPropsType } from './useConfigureIntl.types';

export default function useConfigureIntl({
  locale,
  clientMessagesProvider,
}: UseConfigureIntlPropsType) {
  const [intl, setIntl] = useState<IntlShape>();

  useEffect(() => {
    const func = async () => {
      const { messages } = await clientMessagesProvider();

      return configureIntl({ locale, messages });
    };

    func().then((intl) => {
      setIntlInstance(intl);
      setIntl(intl);
    });
  }, [locale, clientMessagesProvider]);

  return {
    intl,
  };
}
