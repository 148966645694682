import React, { useContext } from 'react';

import { FormContextType } from '../../Form.types';
import { FormContext } from '../../Form.context';
import { GqlError } from '../../../GqlError';
import { FormSubmissionErrorPropsType } from './FormSubmissionError.types';

export default function FormSubmissionError({
  className,
}: FormSubmissionErrorPropsType) {
  const { submissionError: error } = useContext<FormContextType>(FormContext);

  return <GqlError error={error} className={className} />;
}
