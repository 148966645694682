import React, { ComponentType, ReactElement, useState } from 'react';
import classnames from 'classnames';
import isNil from 'lodash/isNil';

import { Error } from '../../common/Error';
import { FieldLabel } from './components/FieldLabel';
import styles from './field.module.scss';
import { FiledPropsType } from './field.types';

const field = <TProps extends FiledPropsType<TProps>>({
  error,
  fieldClassName,
  label,
  tooltip,
  showLabel = 'always',
  showPlaceholder = 'always',
  description,
  hidden = false,
  hideErrorMsg = false,
  ...rest
}: TProps) => {
  return function (Component: ComponentType<any>): ReactElement<TProps> {
    const [isFocused, setFocusedStatus] = useState(false);
    const hasError = !!error;

    const {
      onBlur,
      onFocus,
      // The component intended to be used only for controlled components
      // this way the value is expected to be accessible here
      value,
      className,
      disabled,
      placeholder,
    } = rest as any;

    const onFocusWrapper = (e: React.FocusEvent<any>) => {
      setFocusedStatus(true);

      onFocus && onFocus(e);
    };

    const onBlurWrapper = (e: React.FocusEvent<any>) => {
      setFocusedStatus(false);

      onBlur && onBlur(e);
    };

    const isLabelShown =
      showLabel === 'always' ||
      showLabel === 'alwaysMinified' ||
      isFocused ||
      !!value;
    const isPlaceholderShown = showPlaceholder === 'always' || isFocused;
    const isLabelMinified =
      // The next line assumes that component is controlled. To avoid
      // overlapping of the value and the label in case when component
      // is uncontrolled, please, use one of the following:
      // * showLabel === "alwaysMinified"
      // * placeholder === "" || placeholder === undefined
      (!isNil(value) && value.length !== 0) ||
      isFocused ||
      (!!placeholder && showPlaceholder === 'always') ||
      showLabel === 'onFocus' ||
      showLabel === 'alwaysMinified';

    return (
      <div
        className={classnames(
          styles.field,
          {
            [styles.hidden]: hidden,
            [styles.showError]: hasError && !hideErrorMsg,
          },
          fieldClassName
        )}
      >
        <FieldLabel
          minimize={isLabelMinified}
          disabled={disabled}
          hasError={hasError}
          title={isLabelShown ? label : ''}
          tooltip={tooltip}
        >
          <Component
            {...rest}
            className={classnames(className, {
              [styles.animatedPlaceholder]: showPlaceholder === 'onFocus',
              [styles.placeholderShown]: isPlaceholderShown,
            })}
            hasError={hasError}
            onBlur={onBlurWrapper}
            onFocus={onFocusWrapper}
          />
        </FieldLabel>
        {description ? (
          <p className={styles.description}>{description}</p>
        ) : null}
        {hasError && !hideErrorMsg ? <Error error={error} /> : null}
      </div>
    );
  };
};

export default field;
