import { ApolloError } from '@apollo/client';
import { dateManager } from '@appclose/core';
import * as Sentry from '@sentry/react';

import { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } from '../constants/env';

interface TracingPayload {
  [key: string]: string | number | boolean;
}

export const isTracingEnabled = Boolean(SENTRY_DSN);

let ERROR_CODES_TO_SKIP: string[] = [];

export const initErrorTracing = (data: {
  tags?: TracingPayload;
  extra?: TracingPayload;
  errorCodesToSkip?: string[];
}) => {
  if (!isTracingEnabled) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
  });

  if (data.tags) {
    addTracingScopeTags(data.tags);
  }

  if (data.errorCodesToSkip) {
    ERROR_CODES_TO_SKIP = data.errorCodesToSkip;
  }

  addTracingExtraData({
    timeZoneOffset: dateManager().getTimezoneOffset(),
    timeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone || 'unknown',
    ...(data.extra || {}),
  });
};

export const traceError = (e: Error | ApolloError) => {
  if (!isTracingEnabled) {
    return;
  }

  const gqlErrorsToReport = (e as
    | ApolloError
    | undefined)?.graphQLErrors?.filter(
    (err) => !ERROR_CODES_TO_SKIP.includes(err?.extensions?.code)
  );

  if ((e as ApolloError)?.graphQLErrors && !gqlErrorsToReport?.length) {
    return;
  }

  Sentry.captureException(e);
};

/**
 * Provide tags to filter traced errors in sentry
 * @param data key-value dictionary
 */
export const addTracingScopeTags = (data: TracingPayload) => {
  Sentry.configureScope(function (scope) {
    scope.setTags(data);
  });
};

/**
 * Provide extra data for traced errors
 * @param data key-value dictionary
 */
export const addTracingExtraData = (data: TracingPayload) => {
  Sentry.configureScope(function (scope) {
    scope.setExtras(data);
  });
};

export const setTracingUser = (id: string, data: TracingPayload) => {
  Sentry.withScope(function (scope) {
    scope.setUser({ ...data, id });
  });
};
