import React, { useState } from 'react';
import classnames from 'classnames';

import styles from './Avatar.module.scss';
import { AvatarType } from './Avatar.types';

const AVATAR_INITIALS_SIZE_RATIO = 0.4;

export default function Avatar({
  icon,
  size = 40,
  className,
  onShowIconError,
  color,
  initials,
}: AvatarType): React.ReactElement {
  const [iconVisible, setIconVisible] = useState(false);

  const handleImageLoad = () => setIconVisible(true);
  const showInitials = !icon || !iconVisible;

  return (
    <div
      className={classnames(styles.avatar, className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        // @ts-ignore
        backgroundColor: showInitials ? color : undefined,
      }}
    >
      {showInitials && (
        <span
          className={styles.avatarInitials}
          style={{ fontSize: Math.round(size * AVATAR_INITIALS_SIZE_RATIO) }}
        >
          {initials}
        </span>
      )}

      {icon && (
        <img
          style={{ opacity: iconVisible ? 1 : 0 }}
          className={styles.avatarImage}
          src={icon}
          width={size}
          height={size}
          alt=""
          onLoad={handleImageLoad}
          onError={onShowIconError}
        />
      )}
    </div>
  );
}
