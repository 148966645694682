import { useCallback, useContext } from 'react';
import noop from 'lodash/noop';
import { PopupModalProviderContext } from '../../components/common/PopupModalProvider/PopupModalProvider.context';
import { PopupModalComponentType } from '../../components/common/PopupModalProvider/PopupModalProvider.types';

export default function usePopupModal<
  TReturnData,
  TComponentType extends PopupModalComponentType<TReturnData, any>,
  TData extends Omit<Parameters<TComponentType>['0'], 'onComplete' | 'onCancel'>
>(
  Component: TComponentType,
  onComplete: Parameters<TComponentType>['0']['onComplete'] = noop
) {
  const { onOpen, onClose } = useContext(PopupModalProviderContext);

  const onOpenModal = useCallback(
    (data?: TData) => {
      onOpen(Component, onComplete, data);
    },
    [Component, onComplete, onOpen]
  );

  return { openPopupModal: onOpenModal, closePopupModal: onClose };
}
