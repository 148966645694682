import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { history } from '@appclose/core';

import {
  PopupModalProviderPropsType,
  PopupModalProviderStateType,
} from './PopupModalProvider.types';
import { PopupModalProviderContext } from './PopupModalProvider.context';

export default function PopupModalProvider({
  children,
}: PopupModalProviderPropsType) {
  const [
    { PopupModal, onComplete, data },
    setPopup,
  ] = useState<PopupModalProviderStateType>({});

  const handleOnOpen = useCallback((Modal, onComplete, data) => {
    setPopup(({ PopupModal }) => {
      if (PopupModal) {
        throw new Error('The other PopupModal has already been opened');
      }

      return {
        PopupModal: Modal,
        onComplete,
        data,
      };
    });
  }, []);

  const handleOnComplete = useCallback(
    (data) => {
      onComplete && onComplete(data);
      setPopup({});
    },
    [onComplete]
  );

  const handleOnCancel = useCallback(() => {
    setPopup({});
  }, []);

  const modalProps = useMemo(
    () => ({
      onComplete: handleOnComplete,
      onCancel: handleOnCancel,
      ...(data || {}),
    }),
    [data, handleOnCancel, handleOnComplete]
  );

  const contextValue = useMemo(
    () => ({
      onOpen: handleOnOpen,
      onClose: handleOnCancel,
    }),
    [handleOnCancel, handleOnOpen]
  );

  useEffect(() => history.listen(() => handleOnCancel()), [handleOnCancel]);

  return (
    <PopupModalProviderContext.Provider value={contextValue}>
      {children}
      {PopupModal && <PopupModal {...modalProps} />}
    </PopupModalProviderContext.Provider>
  );
}
