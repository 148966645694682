import { createContext } from 'react';

import { PopupModalProviderContextType } from './PopupModalProvider.types';

export const PopupModalProviderContext = createContext<
  PopupModalProviderContextType<any, any>
>({
  onOpen() {
    throw new Error("PopupModalProvider context hasn't been defined");
  },
  onClose() {
    throw new Error("PopupModalProvider context hasn't been defined");
  },
});
