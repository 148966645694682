import React, { useMemo } from 'react';

import { parseI18nErrorMessage } from '../../controllers/intl';
import I18n from '../I18n';
import { I18nErrorPropsType } from './I18nError.types';

export default function I18nError({ error }: I18nErrorPropsType) {
  const parsedError = useMemo(() => {
    if (!error) {
      return;
    }

    try {
      return parseI18nErrorMessage(error);
    } catch (e) {
      return error;
    }
  }, [error]);

  if (!parsedError) {
    return null;
  }

  if (typeof parsedError === 'string') {
    return <>{parsedError}</>;
  }

  return <I18n {...parsedError} />;
}
