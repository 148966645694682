import React, { forwardRef } from 'react'

import { Caption2 } from '@appclose/ui';
import { AttachmentLightIcon } from '@appclose/icons';
import { useDropArea } from '@appclose/core';
import classnames from 'classnames';

import { FileInputPropsType } from './FileInput.types';
import styles from './FileInput.module.scss';

const FileInput = forwardRef<HTMLInputElement, FileInputPropsType>((props, ref) => {
  const {
    children,
    theme,
    accept,
    multiple = false,
    dndLabelText,
    selectFileLabelText,
    onDropFiles,
    onFileInputChange,
    onLabelClick,
  } = props;

  const [bond, { over }] = useDropArea({ onFiles: onDropFiles });

  return (
    <div
      {...bond}
      className={classnames(styles.dropZone, theme?.dropZone, {
        [styles.dropZoneHover]: over,
      })}
    >
      <div className={styles.dropZoneContent}>
        {children}
        <Caption2
          weight="light"
          className={classnames(
            styles.dragAndDropLabel,
            theme?.dragAndDropLabel
          )}
        >
          <AttachmentLightIcon className={styles.attachIcon} />
          {dndLabelText}
          <label onClick={onLabelClick} className={styles.fileInputLabel}>
            {selectFileLabelText}
            <input
              ref={ref || null}
              type="file"
              multiple={multiple}
              accept={accept}
              className={styles.fileInput}
              onChange={onFileInputChange}
            />
          </label>
        </Caption2>
      </div>
      <div
        className={classnames(
          styles.dropZoneOverlay,
          theme?.dropZoneOverlay
        )}
      />
    </div>
  )
});

export default FileInput;
