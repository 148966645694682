import { FormikProps } from 'formik';
import get from 'lodash/get';
import {
  flattenObject,
  isElementInViewport,
  scrollIntoView,
} from '@appclose/lib';

export function getFieldError(
  form: FormikProps<any>,
  name: string
): string | undefined | { [key: string]: string }[] {
  // https://github.com/jaredpalmer/formik/issues/691#issuecomment-446509600
  const shouldValidate =
    (get(form.touched, name) && form.validateOnChange) || form.submitCount > 0;

  return shouldValidate ? (get(form.errors, name) as string) : undefined;
}

export function scrollToErrorField<Values>(
  formProps: FormikProps<Values>,
  formElement: HTMLFormElement
) {
  const { isValidating, isSubmitting, errors } = formProps;

  // https://jaredpalmer.com/formik/docs/guides/form-submission#frequently-asked-questions
  // See: How do I know when my form is validating before submit?
  if (!isValidating && isSubmitting) {
    const flattenErrors = flattenObject(errors);

    const errorFieldsNames =
      Object.keys(flattenErrors).filter(
        (key) => flattenErrors[key] !== undefined
      ) || '';

    const fieldsWithErrors = errorFieldsNames
      .map((filedName) => {
        const nameForQuery = filedName.replace(/\./g, '\\.');

        return formElement.querySelector(
          `[name=${nameForQuery}], #${nameForQuery}`
        );
      })
      .sort((a, b) => {
        const ay = a?.getBoundingClientRect().y || Infinity;
        const by = b?.getBoundingClientRect().y || Infinity;

        return ay - by;
      });

    const element = fieldsWithErrors[0];

    if (element && !isElementInViewport(element)) {
      scrollIntoView(element as HTMLElement);
    }
  }
}
