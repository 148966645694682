import React, { ChangeEvent, useCallback } from 'react';
import { Field } from 'formik';
import { Flex, Toggle, ToggleTypes } from '@appclose/ui';

import { Hint } from '../../../../common/Hint';

import { ToggleFormFieldType } from './ToggleFormField.types';
import styles from './ToggleFormField.module.scss';

const ToggleFormFieldWrapper = ({
  field,
  form,
  label,
  tooltip,
  onChange,
  ...rest
}: ToggleFormFieldType) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }

      form.setFieldValue(field.name, event.target.checked, true);
    },
    [form, field, onChange]
  );

  return (
    <Toggle
      {...field}
      {...rest}
      label={
        tooltip ? (
          <Flex alignItems="center">
            {label}
            <Hint content={tooltip} iconClassName={styles.hint} />
          </Flex>
        ) : (
          label
        )
      }
      active={field.value || false}
      onChange={handleOnChange}
    />
  );
};

const ToggleFormField = (
  props: Omit<ToggleTypes, 'active'> & Pick<ToggleFormFieldType, 'tooltip'>
) => {
  return <Field {...props} component={ToggleFormFieldWrapper} />;
};

export default ToggleFormField;
