import { Select } from '../../../../common/Select';
import field from '../../../../hoc/field';
import { SelectFieldPropsType } from './SelectField.types';

const SelectField = ({
  showPlaceholder = 'onFocus',
  ...rest
}: SelectFieldPropsType) => field({ showPlaceholder, ...rest })(Select);

export default SelectField;
