import React from 'react';
import classnames from 'classnames';

import styles from '../../Menu.module.scss';
import { MenuItemTypes } from './MenuItem.types';

export default function MenuItem({
  className,
  children,
}: MenuItemTypes): React.ReactElement {
  return <li className={classnames(styles.menuItem, className)}>{children}</li>;
}
