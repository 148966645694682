import React from 'react';
import { Route as ReactRouterDom, Redirect } from 'react-router-dom';

import { SIGN_IN_ROUTE } from '../../..';

import { ProtectedRouteType } from './ProtectedRoute.types';

export default function ProtectedRoute({
  isLogged,
  path,
  exact = false,
  component: WrappedComponent
}: ProtectedRouteType): React.ReactElement {
  return (
    <ReactRouterDom
      exact={exact}
      path={path}
      render={(props: any) =>
        isLogged ? (
          <WrappedComponent {...props} />
        ) : (
          <Redirect
            to={{ pathname: SIGN_IN_ROUTE, state: { from: props.location } }}
          />
        )
      }
    />
  );
}
