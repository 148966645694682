import React from 'react';
import { OptionType } from '../../../../common/Select';
import styles from './USAStateSelectFormField.module.scss';

export const defaultUSAStateOptionLabelResolver = ({
  label,
  value,
}: OptionType) =>
  label && value ? (
    <>
      {label}
      <span className={styles.stateLabel}>{value}</span>
    </>
  ) : null;
