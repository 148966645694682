import React from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import ReactNotification from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css';
import './NotificationProvider.module.scss';

export default function NotificationProvider(): React.ReactElement {
  return ReactDOM.createPortal(<ReactNotification />, document.body);
}
