import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Manager, Reference, Popper } from 'react-popper';

import TooltipTarget from './components/TooltipTarget';
import styles from './Tooltip.module.scss';
import { TooltipTypes } from './Tooltip.types';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';

export default function Tooltip({
  position = 'top',
  content,
  theme = 'light',
  className,
  children,
}: TooltipTypes) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (!content) {
    return <>{children}</>;
  }

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <TooltipTarget
            isMouseOnTarget={setIsTooltipOpen}
            ref={
              // @ts-ignore
              (tooltipTargetRef) => ref(tooltipTargetRef?.tooltipTarget)
            }
          >
            {children}
          </TooltipTarget>
        )}
      </Reference>
      {isTooltipOpen &&
        ReactDOM.createPortal(
          <Popper
            placement={position}
            modifiers={[
              {
                name: 'preventOverflow',
                options: { rootBoundary: 'viewport' },
              },
              {
                name: 'offset',
                options: {
                  offset: [0, 15],
                },
              },
            ]}
          >
            {({ ref, style, placement, arrowProps }) => (
              <div
                ref={ref}
                style={style}
                className={classnames(
                  styles.tooltip,
                  {
                    [styles.dark]: theme === 'dark',
                  },
                  className
                )}
                data-placement={placement}
              >
                {content}
                <ArrowIcon className={styles.arrow} />
              </div>
            )}
          </Popper>,
          document.body
        )}
    </Manager>
  );
}
