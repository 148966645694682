import React from 'react';
import { SSN_FREE_MASK, SSN_MASK } from '../../../../..';
import { SecureInputFormField } from '../../../SecureInput';
import { SSNFormFieldType } from './SSNFormField.types';

export default function SSNFormField({
  name = 'ssn',
  label = 'SSN',
  isFree = false,
  ...props
}: SSNFormFieldType) {
  return (
    <SecureInputFormField
      {...props}
      disabledPaste={false}
      name={name}
      label={label}
      mask={isFree ? SSN_FREE_MASK : SSN_MASK}
    />
  );
}
