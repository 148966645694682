import { useIsMobileDevice } from '../../../hooks/device';

import { MediaQueryPropsType } from './MediaQuery.types';

export default function MediaQueryMobile({
  breakpoint,
  children = null,
}: MediaQueryPropsType): any {
  const isMobile = useIsMobileDevice(breakpoint);

  return isMobile ? children : null;
}
