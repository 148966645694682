import { DateManager } from '@appclose/lib';

let _dateManager: DateManager;

export default function dateManager(): DateManager {
  if (!_dateManager) {
    _dateManager = new DateManager();
  }

  return _dateManager;
}
