import React, { ElementType } from 'react';
import classnames from 'classnames';

import { ListPropsType } from './List.types';
import styles from './List.module.scss';

export default function List({ ordered = false, children }: ListPropsType) {
  const Component: ElementType = ordered ? 'ol' : 'ul';

  return (
    <Component
      className={classnames(styles.list, {
        [styles.ordered]: ordered,
      })}
    >
      {children}
    </Component>
  );
}
