import React, { useCallback } from 'react';
import { Field } from 'formik';

import { QuantityField } from '../QuantityField';
import { getFieldError } from '../../../../../utils/formik';

import {
  QuantityFormFieldPropsType,
  QuantityFormFieldWrapperPropsType,
} from './QuantityFormField.types';

function QuantityInputWrapper({
  field: { value, ...field },
  form,
  onChange,
  ...rest
}: QuantityFormFieldWrapperPropsType) {
  const handleOnChange = useCallback(
    (value: number) => {
      onChange?.(value);

      form.setFieldValue(field.name, value, true);
    },
    [onChange, form, field.name]
  );

  return (
    <QuantityField
      {...field}
      {...rest}
      value={value}
      onChange={handleOnChange}
      error={getFieldError(form, field.name)}
    />
  );
}

export default function QuantityFormField(props: QuantityFormFieldPropsType) {
  return <Field {...props} component={QuantityInputWrapper} />;
}
