import React from 'react';
import { v1 as uuid } from 'uuid';
import classnames from 'classnames';

import styles from './Radio.module.scss';
import { RadioType } from './Radio.types';

export default function Radio({
  id,
  name,
  label,
  value,
  defaultChecked,
  checked,
  disabled,
  className,
  style,
  onChange,
  onBlur,
}: RadioType): React.ReactElement {
  const radioId = id || uuid();

  return (
    <div
      className={classnames({ [styles.disabled]: disabled }, className)}
      style={style}
    >
      <input
        type="radio"
        disabled={disabled}
        id={radioId}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        checked={checked}
        className={styles.radioInput}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={radioId} className={styles.radioLabel}>
        <span className={styles.radioIcon} />
        {label && <div>{label}</div>}
      </label>
    </div>
  );
}
