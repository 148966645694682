import React, { useMemo } from 'react';
import classnames from 'classnames';

import { OffsetPropsType } from './Offset.types';
import './Offset.module.scss';

export default function Offset({
  top,
  right,
  bottom,
  left,
  children,
  className,
}: OffsetPropsType) {
  const margins = useMemo(() => {
    return {
      marginTop: typeof top === 'number' ? top : undefined,
      marginRight: typeof right === 'number' ? right : undefined,
      marginBottom: typeof bottom === 'number' ? bottom : undefined,
      marginLeft: typeof left === 'number' ? left : undefined,
    };
  }, [bottom, left, right, top]);

  return (
    <div
      className={classnames(className, {
        [`offset-top-${top}`]: typeof top === 'string',
        [`offset-right-${right}`]: typeof right === 'string',
        [`offset-bottom-${bottom}`]: typeof bottom === 'string',
        [`offset-left-${left}`]: typeof left === 'string',
      })}
      style={margins}
    >
      {children}
    </div>
  );
}
