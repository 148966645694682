import React, { useState } from 'react';
import classnames from 'classnames';
import { ViewIcon, ViewDisabledIcon } from '@appclose/icons';

import { InputFormField } from '../../../Input/components/InputFormField';

import styles from './SecureInputFormField.module.scss';
import { SecureInputFormFieldType } from './SecureInputFormField.types';

const SecureInputFormField = ({
  showElement,
  hideElement,
  className,
  mask,
  disabledPaste = true,
  ...props
}: SecureInputFormFieldType) => {
  const [isShow, setIsShow] = useState(false);
  const toggleShow = () => {
    setIsShow(!isShow);
  };

  return (
    <div className={styles.default}>
      <InputFormField
        {...props}
        autoComplete="off"
        disabledPaste={disabledPaste}
        mask={mask}
        type={isShow || mask ? 'text' : 'password'}
        className={classnames(styles.input, className, {
          [styles.maskedPassword]: !isShow && mask,
        })}
      />
      <button
        type="button"
        tabIndex={-1}
        className={styles.showButton}
        onClick={toggleShow}
      >
        {hideElement && showElement ? (
          isShow ? (
            hideElement
          ) : (
            showElement
          )
        ) : (
          <div className={styles.eyeIcon}>
            {!isShow ? <ViewIcon /> : <ViewDisabledIcon />}
          </div>
        )}
      </button>
    </div>
  );
};

export default SecureInputFormField;
