import React from 'react';
import classnames from 'classnames';

import { MenuLinkType, MenuLinkElementType } from './MenuLink.types';
import styles from '../../Menu.module.scss';

export default function MenuLink({
  as: Component,
  to,
  exact = false,
  activeClassName = styles.menuLinkActive,
  className,
  children,
  ...props
}: MenuLinkType): React.ReactElement {
  const reactElementProps: MenuLinkElementType = {};

  // Needed for exclude some props for native HTML elements (such as "a")
  if (typeof Component !== 'string') {
    reactElementProps.to = to;
    reactElementProps.exact = exact;
    reactElementProps.activeClassName = activeClassName;
  }

  return (
    <Component
      className={classnames(styles.menuLink, className)}
      {...reactElementProps}
      {...props}
    >
      {children}
    </Component>
  );
}
