import React from 'react';
import noop from 'lodash/noop';
import { Button, ButtonGroup, Caption1, Flex, Offset } from '@appclose/ui';

import { Modal } from '../Modal';

import { ConfirmType } from './Confirm.types';
import styles from './Confirm.module.scss';
import classnames from 'classnames';

export default function Confirm({
  cancelButtonTitle,
  content,
  error,
  isProcessing,
  name,
  okButtonSkin,
  okButtonTitle,
  contentClassName,
  onCancel,
  onConfirm,
  title,
}: ConfirmType) {
  return (
    <Modal onClose={onCancel || noop} title={name}>
      <section className={styles.container}>
        <Flex
          justify="center"
          direction="column"
          className={classnames(styles.content, {
            [styles.contentWithoutName]: !name,
          })}
        >
          {title && (
            <Caption1 weight="bold" offset={{ bottom: 4 }}>
              {title}
            </Caption1>
          )}
          <div className={contentClassName}>{content}</div>
          {error && <Offset top="xs">{error}</Offset>}
        </Flex>
        <Flex as="footer" justify="center">
          <ButtonGroup className={styles.buttons}>
            {!!cancelButtonTitle && (
              <Button
                disabled={isProcessing}
                className={styles.button}
                onClick={onCancel}
              >
                {cancelButtonTitle}
              </Button>
            )}
            {!!okButtonTitle && (
              <Button
                skin={okButtonSkin}
                loading={isProcessing}
                className={styles.button}
                onClick={onConfirm}
              >
                {okButtonTitle}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </section>
    </Modal>
  );
}
