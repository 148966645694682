import React, { useContext } from 'react';
import classnames from 'classnames';

import { AccordionSummaryPropsType } from './AccordionSummary.types';
import { AccordionContext } from '../../Accordion.context';
import styles from './AccordionSummary.module.scss';

export default function AccordionSummary({
  className,
  children,
}: AccordionSummaryPropsType) {
  const { expanded, onChange } = useContext(AccordionContext);

  return (
    <header
      className={classnames(
        styles.summary,
        { [styles.expanded]: expanded },
        className
      )}
      onClick={onChange}
    >
      {children(expanded)}
    </header>
  );
}
