import React from 'react';
import classnames from 'classnames';
import { Button } from '@appclose/ui';
import { PlusSmallIcon } from '@appclose/icons';

import { AddNewPropsType } from './AddNew.types';
import styles from './AddNew.module.scss';

export default function AddNew({
  label,
  className,
  onAdd,
  disabled,
}: AddNewPropsType) {
  return (
    <Button
      skin="shell"
      className={classnames(styles.addNew, className)}
      onClick={onAdd}
      disabled={disabled}
    >
      <div className={styles.button}>
        <PlusSmallIcon fill="#fff" />
      </div>
      <p className={styles.label}>{label}</p>
    </Button>
  );
}
