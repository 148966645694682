import React from 'react';
import get from 'lodash/get';

import { Error } from '../Error';
import { getFieldError } from '../../..';
import { useFormContext } from '../../../components/common/Form';

import { FieldErrorPropsType } from './FieldError.types';

export default function FieldError({ name }: FieldErrorPropsType) {
  const form = useFormContext();
  const plainError = getFieldError(form, name);
  const error =
    !plainError || !Array.isArray(plainError)
      ? plainError
      : plainError.find((item) => typeof get(item, name) === 'string');

  return <Error error={error} />;
}
