import classnames from 'classnames';
import React from 'react';
import { Tab as ReactTab } from 'react-tabs';
import styles from './Tab.module.scss';

import { TabPropsTypes } from './Tab.types';

const Tab = ({
  className,
  selectedClassName,
  disabledClassName,
  ...props
}: TabPropsTypes) => {
  return (
    <ReactTab
      {...props}
      className={classnames(className, styles.tab)}
      selectedClassName={classnames(selectedClassName, styles.selectedTab)}
      disabledClassName={classnames(disabledClassName, styles.disabledTab)}
    />
  );
};

// @ts-ignore
Tab.tabsRole = 'Tab';

export default Tab;
