import React from 'react';

import { Button, Flex, Title6 } from '@appclose/ui';
import { CloseIcon } from '@appclose/icons';
import classnames from 'classnames';

import { ModalOverlay } from './components/ModalOverlay';
import { ModalPropsType } from './Modal.types';
import styles from './Modal.module.scss';

export default function Modal({
  title,
  theme,
  shouldCloseOnOverlayClick = true,
  overlayClassName,
  closeIcon = <CloseIcon />,
  className,
  onClose,
  children,
  headerContent,
}: ModalPropsType) {
  return (
    <ModalOverlay
      onOverlayClick={shouldCloseOnOverlayClick ? onClose : undefined}
      className={classnames(
        styles.modal,
        {
          [styles.page]: theme === 'page',
        },
        className
      )}
      overlayClassName={classnames(
        styles.overlay,
        { [styles.pageOverlay]: theme === 'page' },
        overlayClassName
      )}
    >
      <section className={styles.main}>
        <Flex
          as="header"
          alignItems="center"
          justify="space-between"
          className={styles.header}
        >
          <Flex alignItems="center">
            <Button skin="shell" onClick={onClose}>
              {closeIcon}
            </Button>
            {title && <Title6 className={styles.title}>{title}</Title6>}
          </Flex>
          {headerContent}
        </Flex>
        <>{children}</>
      </section>
    </ModalOverlay>
  );
}
