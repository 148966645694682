export function getFileNameAndExtension(
  name: string
): [string | undefined, string | undefined] {
  const matches = /([^\\]*)\.(\w+)$/.exec(name);

  if (!matches) {
    return [name || undefined, undefined];
  }

  return matches.length > 2
    ? [matches?.[1] || undefined, matches?.[2]]
    : [matches?.[1], undefined];
}

export function getFileName(name: string): string | undefined {
  const [fileName] = getFileNameAndExtension(name);

  return fileName;
}

export function getFileExtension(name: string): string | null {
  const [, fileExtension] = getFileNameAndExtension(name);

  return fileExtension ? fileExtension.toLowerCase() : null;
}

export async function getFileDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result as string);
      };

      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
}

export function downloadFileByUrl(
  url: string,
  {
    filename = 'download',
    inNewTab = false,
  }: {
    filename?: string;
    inNewTab?: boolean;
  } = {}
) {
  const a: HTMLAnchorElement = document.createElement('a');

  a.href = url;
  a.download = filename;

  if (inNewTab) {
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
  }

  const clickHandler = function () {
    a.removeEventListener('click', clickHandler);

    if (a.remove) {
      a.remove();
    } else if (a.parentNode) {
      a.parentNode.removeChild(a);
    }
  };

  a.addEventListener('click', clickHandler, false);

  a.click();
}

export function getDataUri(base64: string, mimetype: string) {
  return `data:${mimetype};base64,${base64}`;
}

export function downloadFileByBase64(
  base64: string,
  mimetype: string,
  filename: string = 'download'
) {
  downloadFileByUrl(getDataUri(base64, mimetype), { filename });
}
