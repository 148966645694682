import React from 'react';
import { Route as ReactRouterDomRoute, Redirect } from 'react-router-dom';

import { ROOT_ROUTE } from '../../../constants/routes';

import { RouteType } from './Route.types';

export default function Route({
  isLogged = false,
  ...props
}: RouteType): React.ReactElement {
  if (isLogged) {
    return <Redirect to={ROOT_ROUTE} />;
  }

  return <ReactRouterDomRoute {...props} />;
}
