import React from 'react';

import { InputFormField } from '../../../../fields/Input';
import { USA_ZIP_CODE_MASK } from '../../../../..';
import { USAZipCodeFormFieldType } from './USAZipCodeFormField.types';

export default function USAZipCodeFormField({
  name = 'zipCode',
  label = 'Zip code',
  ...props
}: USAZipCodeFormFieldType) {
  return (
    <InputFormField
      {...props}
      name={name}
      label={label}
      mask={USA_ZIP_CODE_MASK}
    />
  );
}
