import { createAction } from 'redux-actions';

const MODAL_ACTION = '@@appclose/core/ui/MODAL';
export const MODAL_ACTIONS = {
  OPEN: `${MODAL_ACTION}/OPEN`,
  CLOSE: `${MODAL_ACTION}/CLOSE`,
};

export const openModalAction: (
  type: string,
  data?: any
) => { type: string; data?: any } = createAction(
  MODAL_ACTIONS.OPEN,
  // @ts-ignore
  (type: string, data?: any) => ({ type, data })
);
export const closeModalAction = createAction(MODAL_ACTIONS.CLOSE);
