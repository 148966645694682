import { CurrencyOptionsType } from './Currency.types';

export default class Currency {
  private currencyNumberFormat: Intl.NumberFormat;

  constructor({ locale = 'en-US' }: CurrencyOptionsType = {}) {
    this.currencyNumberFormat = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'USD',
    });
  }

  public format(number: number): string {
    return this.currencyNumberFormat.format(number);
  }
}
