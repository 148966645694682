import isEqual from 'lodash/isEqual';
import { Percent, PercentOptionsType } from '@appclose/lib';

let _percent: Percent;
let _options: PercentOptionsType;

export default function currency(options: PercentOptionsType = {}): Percent {
  if (!_percent || !isEqual(_options, options)) {
    _options = options;
    _percent = new Percent(options);
  }

  return _percent;
}
