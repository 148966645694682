import React from 'react';
import classnames from 'classnames';

import { Hint } from '../../../../common/Hint';

import { FieldLabelPropsType } from './FieldLabel.types';
import styles from './FieldLabel.module.scss';

export default function FieldLabel({
  hasError = false,
  minimize = false,
  title,
  tooltip,
  disabled,
  className,
  children,
  theme,
}: FieldLabelPropsType) {
  return (
    <label
      className={classnames(
        styles.label,
        {
          [classnames(styles.minimize, theme?.minimize)]: minimize,
          [classnames(styles.error, theme?.error)]: hasError,
          [classnames(styles.disabled, theme?.disabled)]: disabled,
        },
        className
      )}
    >
      <div className={classnames(styles.title, theme?.title)}>
        {title && <span>{title}</span>}
        {tooltip && (
          <Hint
            content={tooltip}
            iconClassName={classnames(styles.tooltipIcon, {
              [styles.withMinimizedLabel]: Boolean(title && minimize),
            })}
          />
        )}
      </div>
      {children}
    </label>
  );
}
