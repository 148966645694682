import { DateTimeUnitsType } from './DateManager.types';

export const MOMENT_CALENDAR_SPEC = {
  lastDay: '[Yesterday]',
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  lastWeek: 'L',
  nextWeek: 'L',
  sameElse: 'L',
};

export const MOMENT_CALENDAR_SPEC_WITH_TIME = {
  lastDay: '[Yesterday at] LT',
  sameDay: '[Today at] LT',
  nextDay: '[Tomorrow at] LT',
  lastWeek: '[last] dddd [at] LT',
  nextWeek: 'dddd [at] LT',
  sameElse: 'L',
};

export const TIME_UNITS_SECONDS_DURATION: Record<DateTimeUnitsType, number> = {
  year: 31536000,
  month: 2592000,
  week: 592200,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
  millisecond: 0.001,
};
