import React, { ReactElement } from 'react';
import classnames from 'classnames';

import { TableHeadPropsType } from './TableHead.types';
import styles from './TableHead.module.scss';
import { TableCellPropsType } from '../TableCell';

export default function TableHead({
  className,
  children,
  theme,
}: TableHeadPropsType) {
  return (
    <thead
      className={classnames(
        styles.head,
        {
          [styles.medium]: theme === 'medium',
        },
        className
      )}
    >
      {React.Children.map(children, (rowElement) =>
        React.cloneElement(rowElement, {
          className: classnames(rowElement.props.className, styles.row),
          children: React.Children.toArray(rowElement.props.children)
            .reduce<ReactElement<TableCellPropsType>[]>((acc, elem) => {
              if (React.isValidElement(elem)) {
                if (elem.type === React.Fragment) {
                  acc.push(...elem.props.children);
                } else {
                  acc.push(elem);
                }
              }

              return acc;
            }, [])
            .map(
              (cellElement, index) =>
                React.isValidElement(cellElement) &&
                React.cloneElement(cellElement, {
                  key: index,
                  isHeadCell: true,
                  className: classnames(cellElement.props.className, styles.th),
                })
            ),
        })
      )}
    </thead>
  );
}
