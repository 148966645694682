import React, { ChangeEvent, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import {
  MinusIcon,
  PlusMediumIcon,
  ArrowDownLightIcon,
  ArrowUpLightIcon,
} from '@appclose/icons';

import { QuantityInputPropsType } from './QuantityInput.types';
import styles from './QuantityInput.module.scss';
import { Input } from '../Input';

export default function QuantityInput({
  name,
  value = 0,
  min = -Infinity,
  max = +Infinity,
  disabled,
  asField,
  onChange,
  onBlur,
}: QuantityInputPropsType) {
  const InputComponent = asField ? Input : 'input';
  const handleOnChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const parsedValue = parseInt(target.value, 10) || 0;
      const nextValue =
        parsedValue < min || parsedValue > max ? 0 : parsedValue;

      target.value = String(nextValue);

      onChange(nextValue);
    },
    [min, max, onChange]
  );

  const handleOnMinusClick = useCallback(() => {
    const nextValue = value - 1;

    onChange(nextValue >= min ? nextValue : min);
  }, [value, min, onChange]);

  const handleOnPlusClick = useCallback(() => {
    const nextValue = value + 1;

    onChange(nextValue <= max ? nextValue : max);
  }, [value, max, onChange]);

  useEffect(() => {
    if (min > value) {
      onChange(min);
    }
  }, [min, value, onChange]);

  useEffect(() => {
    if (max < value) {
      onChange(max);
    }
  }, [max, value, onChange]);

  return (
    <div
      className={classnames(styles.quantity, {
        [styles.disabled]: disabled,
        [styles.field]: asField,
      })}
    >
      <button
        type="button"
        className={styles.button}
        onClick={handleOnMinusClick}
        disabled={disabled || value <= min}
      >
        {asField ? <ArrowDownLightIcon /> : <MinusIcon />}
      </button>
      <InputComponent
        type="number"
        name={name}
        value={value}
        min={min}
        max={max}
        disabled={disabled}
        className={styles.input}
        onChange={handleOnChange}
        onBlur={onBlur}
      />
      <button
        type="button"
        className={styles.button}
        onClick={handleOnPlusClick}
        disabled={disabled || value >= max}
      >
        {asField ? <ArrowUpLightIcon /> : <PlusMediumIcon />}
      </button>
    </div>
  );
}
