import React from 'react';
import classnames from 'classnames';
import { ApolloError } from '@apollo/client';

import { i18nMessage } from '../../../../../i18n';

import { FormGqlErrorListPropsType } from './FormGqlErrorList.types';
import styles from './FormGqlErrorList.module.scss';

const getCommonErrorMessage = (error: ApolloError | null | undefined) => {
  if (error?.networkError?.name === 'ServerParseError') {
    return 'Something went wrong. Try again later';
  }

  return (
    error?.networkError?.message || (!error?.graphQLErrors && error?.message)
  );
};

export default function FormGqlErrorList({
  error,
  className,
}: FormGqlErrorListPropsType) {
  if (!error) {
    return null;
  }

  const commonErrorMessage = getCommonErrorMessage(error);

  return (
    <ul className={classnames(styles.formErrors, className)}>
      {commonErrorMessage && <li>{commonErrorMessage}</li>}
      {error.graphQLErrors?.map(({ message, extensions }, i) => {
        const { code, message: innerMessage } = extensions || {};
        const i18nMessageByCode = code && i18nMessage(code);

        if (i18nMessageByCode !== code) {
          return <li key={i}>{i18nMessageByCode}</li>;
        }

        if (!innerMessage) {
          return <li key={i}>{message}</li>;
        }

        return (
          <li>
            {message}: {innerMessage}
          </li>
        );
      })}
    </ul>
  );
}
