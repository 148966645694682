import { AxiosError, AxiosTransformer } from 'axios';

export type AuthorizationTokenProviderType = () => string;

export type AuthorizationHeaderProviderType = (
  authorizationToken: string
) => { string: string } | {};

export type HttpOnErrorType = (error: AxiosError | Error) => void;

export type HttpOptionsType = {
  baseURL?: string;
  headers?: object;
  authorizationTokenProvider?: AuthorizationTokenProviderType;
  authorizationHeaderProvider?: AuthorizationHeaderProviderType;
  onError?: HttpOnErrorType;
  transformRequest?: boolean;
  requestTransformMiddlewares?: AxiosTransformer[];
  responseTransformMiddlewares?: AxiosTransformer[];
};

export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export interface HttpRequestMethodType {
  method: HttpMethods;
  url: string;
  data?: object;
  params?: object;
}
