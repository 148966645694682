import React from 'react';
import { Flex, Text3, Title1 } from '@appclose/ui';

import { ReactComponent as MonesqIcon } from 'assets/monesq.svg';
import Block from 'components/common/Block';

import { ReactComponent as UnderConstructionIcon } from './assets/under-construction.svg';
import styles from './UnderConstruction.module.scss';

export default function UnderConstruction() {
  return (
    <Flex
      as="section"
      direction="column"
      alignItems="center"
      className={styles.page}
    >
      <Flex as="main" direction="column" className={styles.main}>
        <Flex direction="column" alignItems="center">
          <MonesqIcon className={styles.logo} />
        </Flex>
        <Block className={styles.block}>
          <UnderConstructionIcon className={styles.underConstructionIcon} />
          <Title1 offset={{ bottom: 12 }}>Under construction...</Title1>
          <Text3>
            We are currently working on this page. Please come back later.
          </Text3>
        </Block>
      </Flex>
    </Flex>
  );
}
