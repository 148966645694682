import classnames from 'classnames';
import React from 'react';
import styles from './FlexItem.module.scss';
import { FlexItemType } from './FlexItem.types';

export default function FlexItem({
  as: Component = 'div',
  align = 'auto',
  children,
  className,
  grow = 0,
  shrink = 1,
}: FlexItemType) {
  return (
    <Component
      className={classnames(
        styles.item,
        {
          [styles.alignSelfBaseline]: align === 'baseline',
          [styles.alignSelfAuto]: align === 'auto',
          [styles.alignSelfCenter]: align === 'center',
          [styles.alignSelfFlexEnd]: align === 'flex-end',
          [styles.alignSelfFlexStart]: align === 'flex-start',
          [styles.alignSelfStretch]: align === 'stretch',
        },
        className
      )}
      style={{ flexGrow: grow, flexShrink: shrink }}
    >
      {children}
    </Component>
  );
}
