import React from 'react';

import { getFieldError, useFormContext } from '../../../../..';
import { DatePickerValueType } from '../../../../common/DatePicker';
import DatePickerField from '../DatePickerFiled';
import { DatePickerFormFieldWrapperType } from './DatePickerFormFieldWrapper.types';

const DatePickerFormFieldWrapper = ({
  field: { name, value },
  form,
  onChange,
  ...rest
}: DatePickerFormFieldWrapperType) => {
  const { handleBlur } = useFormContext();
  const error = getFieldError(form, name);

  const handleOnChange = (value: DatePickerValueType) => {
    form.setFieldValue(name, value, true);

    onChange && onChange(value);
  };

  return (
    <DatePickerField
      {...rest}
      onChange={handleOnChange}
      value={value}
      name={name}
      error={error}
      onBlur={handleBlur}
    />
  );
};

export default DatePickerFormFieldWrapper;
