import React from 'react';
import classnames from 'classnames';

import styles from './RadioGroup.module.scss';
import { RadioGroupTypes } from './RadioGroup.types';

export default function RadioGroup({
  className,
  children,
}: RadioGroupTypes): React.ReactElement {
  return (
    <div className={className}>
      {React.Children.map(
        children,
        (Element) =>
          Element &&
          React.cloneElement(Element, {
            className: classnames(styles.radio, Element.props.className),
          })
      )}
    </div>
  );
}
