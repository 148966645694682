import React, { useContext, useRef } from 'react';
import classnames from 'classnames';

import { AccordionDetailsPropsType } from './AccordionDetails.types';
import { AccordionContext } from '../../Accordion.context';
import styles from './AccordionDetails.module.scss';

export default function AccordionDetails({
  className,
  children,
}: AccordionDetailsPropsType) {
  const { expanded } = useContext(AccordionContext);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classnames(
        styles.details,
        { [styles.expanded]: expanded },
        className
      )}
      style={{ maxHeight: ref.current?.scrollHeight }}
      ref={ref}
    >
      {children}
    </div>
  );
}
