import React, { useContext } from 'react';
import { Note } from '@appclose/ui';

import { GqlErrorPropsType } from './GqlError.types';
import { GqlErrorContext } from './GqlError.context';

export default function GqlError({ error, className }: GqlErrorPropsType) {
  const { errorResolver } = useContext(GqlErrorContext);
  const errorCode = error?.graphQLErrors[0]?.extensions?.code || null;

  if (!errorCode) {
    return null;
  }

  return (
    <Note theme="error" className={className}>
      {errorResolver(errorCode)}
    </Note>
  );
}
