import React from 'react';
import ReactDOM from 'react-dom';
import { APP_ROOT_ELEMENT_ID } from '@appclose/core';

import UnderConstruction from './UnderConstruction';

ReactDOM.render(
  <UnderConstruction />,
  document.getElementById(APP_ROOT_ELEMENT_ID)
);
