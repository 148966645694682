import React, { useCallback } from 'react';
import { Button, Grid } from '@appclose/ui';
import { ArrowDownIcon } from '@appclose/icons';

import { MovableGroupPropsType } from './MovableGroup.types';
import styles from './MovableGroup.module.scss';

export default function MovableGroup({
  enabled,
  index,
  length,
  children,
  onClick,
}: MovableGroupPropsType) {
  const handleOnClick = useCallback(
    (direction: 'up' | 'down') => {
      onClick({ from: index, to: direction === 'up' ? index - 1 : index + 1 });
    },
    [index, onClick]
  );
  const handleOnUpClick = useCallback(() => {
    handleOnClick('up');
  }, [handleOnClick]);
  const handleOnDownClick = useCallback(() => {
    handleOnClick('down');
  }, [handleOnClick]);

  return enabled ? (
    <Grid columns="30px 1fr" gap={[20]} align="center">
      <div className={styles.control}>
        <Button
          skin="shell"
          className={styles.button}
          disabled={index === 0}
          onClick={handleOnUpClick}
        >
          <ArrowDownIcon className={styles.icon} />
        </Button>
        <Button
          skin="shell"
          className={styles.button}
          disabled={index + 1 === length}
          onClick={handleOnDownClick}
        >
          <ArrowDownIcon className={styles.icon} />
        </Button>
      </div>
      {children}
    </Grid>
  ) : (
    <>{children}</>
  );
}
