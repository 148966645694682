import React from 'react';

import { ListItemPropsType } from './ListItem.type';
import styles from './ListItem.module.scss';

export default function ListItem({ children }: ListItemPropsType) {
  return (
    <li className={styles.item}>
      <div>{children}</div>
    </li>
  );
}
