import React from 'react';
import { Input, InputType } from '@appclose/ui';

import field, { FiledPropsType } from '../../../../hoc/field';
import { MaskedInput } from '../MaskedInput';
import { MaskedInputFiledType } from '../MaskedInput/MaskedInput.types';

const InputField = React.forwardRef<
  HTMLInputElement,
  FiledPropsType<InputType & Partial<MaskedInputFiledType>>
>((props, ref) =>
  field<
    Partial<MaskedInputFiledType> &
      InputType &
      Pick<FiledPropsType<undefined>, 'hidden'>
  >({
    ...props,
    hidden: props.type === 'hidden',
    ref,
  })(props.mask ? MaskedInput : Input)
);

export default InputField;
