import { SessionOptionsType } from './Session.types';

export default class Session {
  private readonly accessTokenKey: string;
  private readonly refreshTokenKey: string;
  private readonly storage: Storage;

  constructor({
    accessTokenKey = 'AUTHORIZATION_TOKEN',
    refreshTokenKey = 'REFRESH_TOKEN',
    storage,
  }: SessionOptionsType = {}) {
    this.accessTokenKey = accessTokenKey;
    this.refreshTokenKey = refreshTokenKey ?? '';
    this.storage = storage === 'sessionStorage' ? sessionStorage : localStorage;
  }

  public getAccessToken(): string | null {
    return this.storage.getItem(this.accessTokenKey);
  }

  public getRefreshToken(): string | null {
    return this.storage.getItem(this.refreshTokenKey);
  }

  public setAccessToken(accessToken: string): void {
    this.storage.setItem(this.accessTokenKey, accessToken);
  }

  public setRefreshToken(refreshToken: string): void {
    this.storage.setItem(this.refreshTokenKey, refreshToken);
  }

  public deleteAccessToken(): void {
    this.storage.removeItem(this.accessTokenKey);
  }

  public deleteRefreshToken(): void {
    this.storage.removeItem(this.refreshTokenKey);
  }

  public deleteTokens(): void {
    this.storage.removeItem(this.accessTokenKey);
    this.storage.removeItem(this.refreshTokenKey);
  }

  public hasAccessToken(): boolean {
    return !!this.getAccessToken();
  }

  public hasRefreshToken(): boolean {
    return !!this.getRefreshToken();
  }
}
