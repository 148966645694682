import React from 'react';
import classnames from 'classnames';
import Loader from '../Loader';

import styles from './OverlayLoader.module.scss';

import { OverlayLoaderPropsType } from './OverlayLoader.types';

export default function OverlayLoader({
  as: Component = 'div',
  loading,
  position = 'absolute',
  className,
  children,
}: OverlayLoaderPropsType) {
  const isSticky = position === 'sticky';

  return (
    <Component
      className={classnames(styles.container, className, {
        [styles.loading]: loading,
      })}
    >
      {children}
      {loading && (
        <div
          className={classnames(styles.loader, {
            [styles.fixed]: position === 'fixed',
            [styles.absolute]: position === 'absolute' || isSticky,
          })}
        >
          {isSticky ? (
            <div className={styles.sticky}>
              <Loader />
            </div>
          ) : (
            <Loader />
          )}
        </div>
      )}
    </Component>
  );
}
