import { Permission, PermissionsType } from '@appclose/lib';

let _permission: Permission;

export default function permissionProvider(
  permissionResolver?: () => PermissionsType
): Permission {
  if (!_permission && permissionResolver) {
    _permission = new Permission(permissionResolver);
  }

  if (!_permission) {
    throw new Error("Permission instance isn't defined");
  }

  return _permission;
}
