import React, { forwardRef, useMemo } from 'react';
import classnames from 'classnames';

import { BlockPropsType } from './Block.types';
import styles from './Block.module.scss';

const Block = forwardRef<HTMLElement, BlockPropsType>(
  (
    {
      as: Component = 'div',
      className,
      children,
      onClick,
      inset,
      style,
      ...props
    },
    ref
  ) => {
    const paddings = useMemo(() => {
      if (typeof inset === 'number') {
        return { padding: inset };
      }

      if (typeof inset === 'object') {
        return {
          paddingTop: inset?.top,
          paddingRight: inset?.right,
          paddingBottom: inset?.bottom,
          paddingLeft: inset?.left,
        };
      }

      return {};
    }, [inset]);

    return (
      <Component
        className={classnames(
          styles.block,
          {
            [styles.clickable]: !!onClick,
          },
          className
        )}
        onClick={onClick}
        style={{ ...paddings, ...(style || {}) }}
        {...props}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);

export default Block;
