import React, { ChangeEvent, useCallback } from 'react';
import { Field } from 'formik';
import { Checkbox as UICheckbox, CheckboxType } from '@appclose/ui';

import { CheckboxFormFieldType } from './CheckboxFormField.types';

const Checkbox = ({
  field,
  form,
  onChange,
  ...rest
}: CheckboxFormFieldType) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }

      form.setFieldValue(field.name, event.target.checked, true);
    },
    [form, field, onChange]
  );

  return (
    <UICheckbox
      {...field}
      {...rest}
      checked={field.value || false}
      onChange={handleOnChange}
    />
  );
};

const CheckboxFormField = (props: CheckboxType) => {
  return <Field {...props} component={Checkbox} />;
};

export default CheckboxFormField;
