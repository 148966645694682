import axios, { AxiosInstance, AxiosPromise } from 'axios';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';

import { isItHttpTransferableData } from '../..';

import {
  AuthorizationTokenProviderType,
  AuthorizationHeaderProviderType,
  HttpOnErrorType,
  HttpOptionsType,
  HttpMethods,
  HttpRequestMethodType
} from './Http.types';

export default class Http {
  private readonly _authorizationTokenProvider: AuthorizationTokenProviderType;
  private readonly _authorizationHeaderProvider: AuthorizationHeaderProviderType;
  private readonly _onError: HttpOnErrorType;
  private readonly _http: AxiosInstance;

  public constructor({
    baseURL = '/api/',
    headers = {},
    authorizationTokenProvider = () => '',
    authorizationHeaderProvider = () => ({}),
    onError = () => undefined,
    transformRequest = true,
    requestTransformMiddlewares = [],
    responseTransformMiddlewares = []
  }: HttpOptionsType = {}) {
    this._authorizationTokenProvider = authorizationTokenProvider;
    this._authorizationHeaderProvider = authorizationHeaderProvider;
    this._onError = onError;

    this._http = axios.create({
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers
      },
      transformRequest: [
        ...requestTransformMiddlewares,
        data =>
          isItHttpTransferableData(data) || !transformRequest
            ? data
            : snakecaseKeys(data, { deep: true }),
        data => (isItHttpTransferableData(data) ? data : JSON.stringify(data))
      ],
      transformResponse: [
        ...responseTransformMiddlewares,
        data => {
          try {
            return camelcaseKeys(JSON.parse(data), { deep: true });
          } catch (e) {
            return data;
          }
        },
        data => data
      ]
    });
  }

  public get baseUrl() {
    return this._http.defaults.baseURL;
  }

  public get authorizationToken() {
    return this._authorizationTokenProvider();
  }

  public get(url: string, params: object = {}): AxiosPromise {
    return this.request({ method: HttpMethods.GET, url, params });
  }

  public post(url: string, data: object = {}): AxiosPromise {
    return this.request({ method: HttpMethods.POST, url, data });
  }

  public patch(url: string, data: object = {}): AxiosPromise {
    return this.request({ method: HttpMethods.PATCH, url, data });
  }

  public put(url: string, data: object = {}): AxiosPromise {
    return this.request({ method: HttpMethods.PUT, url, data });
  }

  public delete(url: string): AxiosPromise {
    return this.request({ method: HttpMethods.DELETE, url });
  }

  private request({
    method,
    url,
    data = {},
    params = {}
  }: HttpRequestMethodType): AxiosPromise {
    const authorizationToken = this._authorizationTokenProvider();
    const authorizationHeader = this._authorizationHeaderProvider(
      authorizationToken
    );

    return this._http
      .request({
        method,
        url,
        headers: {
          ...authorizationHeader
        },
        params: snakecaseKeys(params as { [key: string]: string }),
        data
      })
      .catch(error => {
        this._onError(error);

        throw error;
      });
  }
}
