import { OptionType, SelectType } from './Select.types';

export const defaultValueResolver = (value: SelectType) => {
  if (value === undefined || value === null) {
    return value;
  }

  return typeof value === 'object' ? value?.id : value.toString();
};
export const defaultOptionLabelResolver = (option: OptionType<SelectType>) => {
  return option?.label !== undefined ? option.label : option || undefined;
};
export const defaultOptionValueResolver = (option: OptionType<SelectType>) => {
  return option?.value !== undefined ? option.value : option || undefined;
};
export const defaultSelectedOptionsResolver = (
  selectedOptions: OptionType<SelectType>[] = [],
  isMulti = false
) => {
  return !isMulti
    ? defaultOptionLabelResolver(selectedOptions[0])
    : selectedOptions
        .map((option) => defaultOptionLabelResolver(option).toString())
        .join(', ');
};
