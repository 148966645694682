import { useIsDesktopDevice } from '../../../hooks/device';

import { MediaQueryPropsType } from './MediaQuery.types';

export default function MediaQueryDesktop({
  breakpoint,
  children = null,
}: MediaQueryPropsType): any {
  const isDesktop = useIsDesktopDevice(breakpoint);

  return isDesktop ? children : null;
}
