import React from 'react';
import stubFalse from 'lodash/stubFalse';
import { SketchPicker } from 'react-color';
import { Dropdown } from '@appclose/core';

import { ColorPickerPropsType } from './ColorPicker.types';

export default function ColorPicker({
  color,
  toggleComponent,
  onChange,
  ...rest
}: ColorPickerPropsType) {
  return (
    <Dropdown
      toggleComponent={() => toggleComponent}
      content={<SketchPicker color={color} onChangeComplete={onChange} />}
      onContentClickHandler={stubFalse}
      {...rest}
    />
  );
}
