import { Field } from 'formik';
import React from 'react';
import DatePickerFormFieldWrapper from '../DatePickerFormFieldWrapper';
import { DateRangePickerFormFieldType } from './DateRangePickerFormField.types';

const DateRangePickerFormField = (props: DateRangePickerFormFieldType) => {
  return <Field {...props} mode="range" component={DatePickerFormFieldWrapper} />;
};

export default DateRangePickerFormField;
