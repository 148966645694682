import React from 'react';
import classnames from 'classnames';

import { TableCellPropsType } from './TableCell.types';
import styles from './TableCell.module.scss';

export default function TableCell({
  isHeadCell = false,
  noWordBreak = false,
  align,
  vAlign,
  theme,
  skin,
  colspan,
  width,
  maxWidth,
  className,
  children,
  onClick,
}: TableCellPropsType) {
  const Component = isHeadCell ? 'th' : 'td';

  return (
    <Component
      style={{ width, maxWidth, textAlign: align, verticalAlign: vAlign }}
      className={classnames(styles.cell, className, {
        [styles.headCell]: isHeadCell,
        [styles.noWordBreak]: noWordBreak,
        [styles.light]: theme === 'light',
        [styles.strong]: theme === 'strong',
        [styles.icon]: skin === 'icon',
        [styles.context]: skin === 'context',
      })}
      colSpan={colspan}
      onClick={onClick}
    >
      {children}
    </Component>
  );
}
