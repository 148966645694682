import { FormDataType } from '../../Form.types';

export const FORM_COMPARED_PROPS: Array<keyof FormDataType<any>> = [
  'dirty',
  'errors',
  'initialErrors',
  'initialStatus',
  'initialTouched',
  'initialValues',
  'isInitialValid',
  'isSubmitting',
  'isValid',
  'isValidating',
  'status',
  'submitCount',
  'touched',
  'values',
];
