import { createContext } from 'react';

import { AccordionContextType } from './Accordion.types';

export const AccordionContext = createContext<AccordionContextType>({
  expanded: false,
  onChange() {
    throw new Error("Accordion context provider hasn't been defined!");
  },
});
