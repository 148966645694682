export { default as scrollIntoView } from 'scroll-into-view';

export function isElementInViewport(el: Element) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function isElementInScroll(el: HTMLElement, scrollElement: HTMLElement) {
  const rect = el.getBoundingClientRect();
  const scrollElementRect = scrollElement.getBoundingClientRect();

  return (
    rect.bottom > scrollElementRect.top && rect.top < scrollElementRect.bottom
  );
}
