import {
  PermissionsType,
  hasPermission,
  hasPermissions,
  AllowPermissionType,
} from '../../utils/permission';

export default class Permission {
  private permissionResolver: () => PermissionsType;

  constructor(permissionResolver: () => PermissionsType) {
    this.permissionResolver = permissionResolver;
  }

  public getPermissions(): PermissionsType {
    return this.permissionResolver();
  }

  public hasPermission(resource: string, action: string, resourceId?: string) {
    return hasPermission(this.getPermissions(), resource, action, resourceId);
  }

  public hasPermissions(allowPermissions: AllowPermissionType) {
    return hasPermissions(this.getPermissions(), allowPermissions);
  }
}
