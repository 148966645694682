import isEqual from 'lodash/isEqual';

import { Notification, NotificationOptionsType } from '../classes/Notification';

let _notification: Notification;
let _options: NotificationOptionsType;

export default function notification(
  options: NotificationOptionsType = {}
): Notification {
  if (!_notification || !isEqual(_options, options)) {
    _options = options;
    _notification = new Notification(options);
  }

  return _notification;
}
