import React from 'react';
import ReactDOM from 'react-dom';
import { TooltipTargetPropsType } from './TooltipTarget.types';

export default class TooltipTarget extends React.Component<
  TooltipTargetPropsType
> {
  tooltipTarget?: Element | Text | null;

  componentDidMount() {
    this.setTooltipTargetEvents();
  }

  componentDidUpdate(prevProps: TooltipTargetPropsType) {
    if (prevProps.children !== this.props.children) {
      this.removeTooltipTargetEvents();
      this.setTooltipTargetEvents();
    }
  }

  componentWillUnmount() {
    this.removeTooltipTargetEvents();
  }

  setTooltipTargetEvents = () => {
    const tooltipTarget = ReactDOM.findDOMNode(this);

    tooltipTarget?.addEventListener('mouseenter', this.onMuseEnter);
    tooltipTarget?.addEventListener('mouseleave', this.onMuseLeave);

    this.tooltipTarget = tooltipTarget;
  };

  removeTooltipTargetEvents = () => {
    this.tooltipTarget?.removeEventListener('mouseenter', this.onMuseEnter);
    this.tooltipTarget?.removeEventListener('mouseleave', this.onMuseLeave);

    this.tooltipTarget = null;
  };

  onMuseEnter = () => {
    const { isMouseOnTarget } = this.props;
    isMouseOnTarget(true);
  };

  onMuseLeave = () => {
    const { isMouseOnTarget } = this.props;
    isMouseOnTarget(false);
  };

  render() {
    const { children } = this.props;

    return children;
  }
}
