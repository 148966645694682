import React, { useCallback } from 'react';

import { InputFormField, PHONE_MASK } from '../../../../..';

import { PhoneNumberFormFieldPropsType } from './PhoneNumberFormField.types';
import { DEFAULT_PLACEHOLDER } from './PhoneNumberFormField.constants';

export default function PhoneNumberFormField({
  name = 'phoneNumber',
  label = 'Phone number',
  placeholder = DEFAULT_PLACEHOLDER,
  showPlaceholder = 'onFocus',
  ...props
}: PhoneNumberFormFieldPropsType) {
  const handleUnmaskValue = useCallback(
    (maskedValue) => maskedValue.replace(/ /g, ''),
    []
  );

  return (
    <InputFormField
      {...props}
      type="tel"
      name={name}
      label={label}
      placeholder={placeholder}
      showPlaceholder={showPlaceholder}
      mask={PHONE_MASK}
      unmaskValue={handleUnmaskValue}
    />
  );
}
