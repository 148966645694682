import React from 'react';
import classnames from 'classnames';

import { Caption3 } from '../Typography';

import { GroupPropsType } from './Group.types';
import styles from './Group.module.scss';

export default function Group({ title, className, children }: GroupPropsType) {
  return (
    <section className={classnames(styles.group, className)}>
      <header>
        <Caption3 weight="black" className={styles.title}>
          {title}
        </Caption3>
      </header>
      <div className={styles.content}>{children}</div>
    </section>
  );
}
