import cloneDeep from 'lodash/cloneDeep';
import {
  Resolvers,
  ApolloClient,
  ApolloError,
  ApolloLink,
  Observable,
  fromPromise,
  InMemoryCache,
} from '@apollo/client';
import { Operation, ObservableSubscription } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { getMainDefinition, getOperationName } from '@apollo/client/utilities';
import noop from 'lodash/noop';
import { createUploadLink } from 'apollo-upload-client';

import { omitDeep, getTokenPayload, Session } from '@appclose/lib';
import { dateManager } from '@appclose/core';

export default function gqlClient({
  session,
  possibleTypes,
  onAuthError,
  onRequestError,
  onRefreshToken,
  resolvers,
  headersProvider = () => ({}),
}: {
  session: Session;
  possibleTypes?: Record<string, string[]>;
  onAuthError(hasToken?: boolean): void;
  onRequestError?(error: ApolloError): void;
  onRefreshToken(): Promise<Response>;
  resolvers?: Resolvers | Resolvers[];
  headersProvider?(): Record<string, string>;
}) {
  const cache = new InMemoryCache({
    possibleTypes,
  });

  let subscriptionClient: any = null;
  const clientServices = {
    logout: async () => {
      session.deleteTokens();
      subscriptionClient?.close(true, true);
      subscriptionClient = null;
    },
    reconnectWs: () => {
      subscriptionClient?.tryReconnect();
    },
  };

  const getRequestHeaders = (token: string | null) => {
    return {
      ...headersProvider(),
      authorization: token ? `Bearer ${token}` : '',
    };
  };

  const getOperationType = ({ query }: Operation) => {
    const definition = getMainDefinition(query);

    return definition.kind === 'OperationDefinition'
      ? definition.operation
      : 'fragment';
  };

  const isOperationSubscription = (operation: Operation) =>
    getOperationType(operation) === 'subscription';

  const handleLogout = async () => {
    await clientServices.logout();
    onAuthError();
  };

  const errorLink = onError((error) => {
    const { graphQLErrors, networkError, operation, forward } = error;

    const isSubscription = isOperationSubscription(operation);
    const operationType = getOperationType(operation);
    const operationName = getOperationName(operation.query);

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, path, extensions }) => {
        if (extensions?.code === 'UNAUTHENTICATED') {
          return;
        }

        console.error(
          `[GraphQL ${operationType} error]: Operation: ${operationName} Message: ${message}, Path: ${path}`
        );
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError.message}`);
    }

    if (graphQLErrors) {
      const isAuthError =
        graphQLErrors.find(
          ({ extensions }) => extensions?.code === 'UNAUTHENTICATED'
        ) !== undefined;

      if (isAuthError && operation.getContext()?.token) {
        return onAuthError(true);
      } else if (isAuthError) {
        return fromPromise(onRefreshToken().catch(handleLogout))
          .filter((value) => Boolean(value))
          .flatMap((accessToken: any) => {
            const oldHeaders = operation.getContext().headers;

            if (!isSubscription) {
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  ...getRequestHeaders(accessToken),
                },
              });
            } else {
              clientServices.reconnectWs();
            }

            return forward(operation);
          });
      } else if (isSubscription) {
        if (error.response) {
          error.response.errors = undefined;
          error.response.data = {};
        }
      }
    }

    if (onRequestError && !isSubscription) {
      onRequestError(new ApolloError({ graphQLErrors, networkError }));
    }
  });

  const request = async (operation: Operation) => {
    const token = operation.getContext().token || session.getAccessToken();

    operation.variables = omitDeep(cloneDeep(operation.variables), [
      '__typename',
    ]);

    operation.setContext({
      headers: getRequestHeaders(token),
    });
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle: ObservableSubscription | undefined = undefined;

        Promise.resolve(operation)
          .then((operation: Operation) => request(operation))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      })
  );

  const tokenRefreshLink = new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
      const token = session.getAccessToken();

      if (!token) {
        return true;
      }

      try {
        const { exp } = getTokenPayload(token);
        const tokenExpirationDate = dateManager().parse(exp, 'X');

        return dateManager().parse().isBefore(tokenExpirationDate);
      } catch {
        return false;
      }
    },
    fetchAccessToken: onRefreshToken,
    handleResponse: (operation: Operation, accessTokenField: string) => (
      accessToken: string
    ) => ({
      data: {
        [accessTokenField]: accessToken,
      },
    }),
    handleFetch: noop,
    handleError: handleLogout,
  });

  const createAppLink = () => {
    const httpUploadLink = createUploadLink({
      credentials: 'include',
    }) as any; // TODO: Should be fixed after `@types/apollo-upload-client` updated;

    const wsLink = new WebSocketLink({
      uri: `${window.location.protocol.includes('https') ? 'wss' : 'ws'}://${
        window.location.hostname
      }:${window.location.port || ''}/graphql`,
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: () => {
          const token = session.getAccessToken();

          return getRequestHeaders(token);
        },
        connectionCallback: function () {
          subscriptionClient = this;
        },
      },
    });

    return ApolloLink.split(
      isOperationSubscription,
      wsLink,
      ApolloLink.from([requestLink, httpUploadLink])
    );
  };

  const appLink = createAppLink();

  return {
    client: new ApolloClient({
      link: ApolloLink.from([tokenRefreshLink as any, errorLink, appLink]), // TODO: Should be fixed after `apollo-link-token-refresh` updated
      cache,
      resolvers,
    }),
    clientServices,
  };
}
