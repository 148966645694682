export function sortByDateField<
  SortObjectType extends { [key: string]: any },
  FieldName extends keyof SortObjectType
>(
  array: SortObjectType[],
  options: {
    order?: 'ASC' | 'DESC';
    fieldName?: FieldName;
  } = {
    order: 'ASC',
    fieldName: 'createdAt' as FieldName
  }
): SortObjectType[] {
  const { order = 'ASC', fieldName = 'createdAt' as FieldName } = options;

  const comparator =
    order === 'ASC'
      ? (a: SortObjectType, b: SortObjectType) =>
          new Date(a[fieldName]).getTime() - new Date(b[fieldName]).getTime()
      : (a: SortObjectType, b: SortObjectType) =>
          new Date(b[fieldName]).getTime() - new Date(a[fieldName]).getTime();

  return [...array].sort(comparator);
}
