import isEqual from 'lodash/isEqual';
import { FileSize, FileSizeOptionsType } from '@appclose/lib';

let _fileSize: FileSize;
let _options: FileSizeOptionsType;

export default function fileSize(options: FileSizeOptionsType = {}): FileSize {
  if (!_fileSize || !isEqual(_options, options)) {
    _options = options;
    _fileSize = new FileSize(options);
  }

  return _fileSize;
}
