import React from 'react';
import { Caption3 } from '@appclose/ui';

import { I18nError } from '../../../i18n';
import { ErrorPropsType } from './Error.types';

export default function Error({ error }: ErrorPropsType) {
  if (!error) {
    return null;
  }

  const errorMsg =
    typeof error === 'object' ? (
      Object.values(error).map((error, i) => (
        <span key={i}>
          <I18nError error={error} />
          <br />
        </span>
      ))
    ) : (
      <I18nError error={error} />
    );

  return (
    <Caption3 weight="light" color="warning" offset={{ top: 4 }}>
      {errorMsg}
    </Caption3>
  );
}
