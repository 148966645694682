import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getIntlMessageId } from '../../controllers/intl';

import { I18nPropsType } from './I18n.types';
import { defaultIntlValueResolvers } from '../../constants';

export default function I18n<T extends string>({
  id,
  values,
  ...props
}: I18nPropsType<T>) {
  return (
    <FormattedMessage
      id={getIntlMessageId<T>(id)}
      values={{
        ...defaultIntlValueResolvers,
        ...values,
      }}
      {...props}
    />
  );
}
