import React from 'react';
import { Button, Grid } from '@appclose/ui';
import { TrashIcon } from '@appclose/icons';

import { DeletableGroupPropsType } from './DeletableGroup.types';
import styles from './DeletableGroup.module.scss';

export default function DeletableGroup({
  onDelete,
  children,
  enabled = true,
  position = 'center',
}: DeletableGroupPropsType) {
  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <Grid
      className={styles.deletableGroup}
      columns="1fr 20px"
      gap={[15]}
      align={position}
    >
      {children}
      {enabled && (
        <Button className={styles.deleteButton} onClick={onDelete} skin="shell">
          <TrashIcon />
        </Button>
      )}
    </Grid>
  );
}
