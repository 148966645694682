import React from 'react';
import classnames from 'classnames';

import { TablePropsType } from './Table.types';
import styles from './Table.module.scss';

export default function Table({ className, children }: TablePropsType) {
  return (
    <table className={classnames(styles.table, className)}>{children}</table>
  );
}
