import React from 'react';
import classnames from 'classnames';

import styles from './ButtonGroup.module.scss';
import { ButtonGroupTypes } from './ButtonGroup.types';

export default function ButtonGroup({
  align,
  className,
  children,
}: ButtonGroupTypes) {
  return (
    <div
      className={classnames(
        styles.buttonGroup,
        {
          [styles.alignCenter]: align === 'center',
        },
        className
      )}
    >
      {React.Children.map(
        children,
        (Element) =>
          Element &&
          React.cloneElement(Element, {
            className: classnames(Element.props.className, styles.button),
          })
      )}
    </div>
  );
}
