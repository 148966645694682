import classnames from 'classnames';
import React from 'react';
import { TabList as ReactTabList } from 'react-tabs';

import styles from './TabList.module.scss';
import { TabListPropsTypes } from './TabList.types';

const TabList = ({ className, ...props }: TabListPropsTypes) => {
  return (
    <ReactTabList
      {...props}
      className={classnames(className, styles.tabList)}
    />
  );
};

// @ts-ignore
TabList.tabsRole = 'TabList';

export default TabList;
