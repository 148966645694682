import filesize from 'filesize';

import { FileSizeOptionsType } from './FileSize.types';

export default class FileSize {
  private readonly options: FileSizeOptionsType;

  constructor(options: FileSizeOptionsType = {}) {
    this.options = options;
  }

  public format(bytes: number, options: FileSizeOptionsType = {}): string {
    return filesize(bytes, { ...this.options, ...options });
  }
}
