import dayjs from 'dayjs';
import pluginCustomParseFormat from 'dayjs/plugin/customParseFormat';
import pluginAdvancedFormat from 'dayjs/plugin/advancedFormat';
import pluginUtc from 'dayjs/plugin/utc';
import pluginCalendar from 'dayjs/plugin/calendar';
import pluginTimezone from 'dayjs/plugin/timezone';
import pluginMinMax from 'dayjs/plugin/minMax';

import { DateTimeType, DateTimeUnitsType } from './DateManager.types';

import {
  TIME_UNITS_SECONDS_DURATION,
  MOMENT_CALENDAR_SPEC,
  MOMENT_CALENDAR_SPEC_WITH_TIME,
} from './DateManager.constants';

dayjs.extend(pluginCustomParseFormat);
dayjs.extend(pluginAdvancedFormat);
dayjs.extend(pluginUtc);
dayjs.extend(pluginCalendar);
dayjs.extend(pluginTimezone);
dayjs.extend(pluginMinMax);

export default class DateManager {
  format(date?: DateTimeType, format: string = 'MMM D, YYYY h:mm A'): string {
    return dayjs(date).format(format || undefined);
  }

  parse(
    input?: dayjs.ConfigType,
    format?: dayjs.OptionType,
    strict?: boolean
  ): dayjs.Dayjs {
    return dayjs(input, format, strict);
  }

  getHumanReadableDate(
    date: DateTimeType,
    { withTime = false, format }: { withTime?: boolean; format?: string } = {}
  ): string {
    const currentMoment = this.parse();
    const dateMoment = this.parse(date);
    const dayDifference = Math.abs(currentMoment.diff(dateMoment, 'days'));

    if (dayDifference <= 1) {
      return dateMoment.calendar(
        null,
        withTime ? MOMENT_CALENDAR_SPEC_WITH_TIME : MOMENT_CALENDAR_SPEC
      );
    }

    const resultFormat =
      format ||
      `${currentMoment.year() !== dateMoment.year() ? 'YYYY ' : ''}MMM D`;

    return dateMoment.format(resultFormat);
  }

  getAge(birthday: DateTimeType): number {
    return this.parse().diff(birthday, 'years');
  }

  getTimezoneOffset(): number {
    return this.parse().utcOffset();
  }

  getTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getSeconds(unitType: DateTimeUnitsType, number: number = 1): number {
    return TIME_UNITS_SECONDS_DURATION[unitType] * number;
  }

  getMilliseconds(unitType: DateTimeUnitsType, number: number = 1): number {
    return this.getSeconds(unitType, number) * 1000;
  }

  roundDuration(duration: number, interval: number): number {
    return Math.ceil(duration / interval) * interval;
  }

  getMinDate(dates: DateTimeType[]): string {
    return dayjs.min([...dates.map((date) => dayjs(date))])?.format();
  }

  getMaxDate(dates: DateTimeType[]): string {
    return dayjs.max([...dates.map((date) => dayjs(date))])?.format();
  }
}
